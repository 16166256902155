.headerBanner {
	background-image:url("./network.jpg");
	background-position:center center;
	background-size:cover;
	min-height: 200px;
	max-height: 500px;
	
}

.headerBanner  p{
		font-size: 4em;
	text-align: left;
	padding-left: 50px;
	 vertical-align: middle;
	 color: white;
}