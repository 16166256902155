.orderNoteItem {
border: 1px solid black;
text-align: justify;
margin: 10px;
}
.orderNoteText {

}
.orderNoteDate {
font-size: 0.7em;
}