.icon {
width: 100px;
}

.box1 {
	background-color: #a0c4ff;
	padding: 10px;
	height: 9em;
	margin: 10px;
	border-radius: 25px;
}

.center {
  justify-content: center;
}