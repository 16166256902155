@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
html {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#cfe7fa+0,6393c1+100;Grey+Blue+3D */
/*background: #cfe7fa; /* Old browsers */
/*background: #e8f0ff;*/
/*background: -moz-linear-gradient(-45deg, #cfe7fa 0%, #6393c1 100%); /* FF3.6-15 */
/*background: -webkit-linear-gradient(-45deg, #cfe7fa 0%,#6393c1 100%); /* Chrome10-25,Safari5.1-6 */
/*background: linear-gradient(135deg, #cfe7fa 0%,#6393c1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cfe7fa', endColorstr='#6393c1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
/*
background: url("./15490.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
*/
}
body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
	
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.headerBanner {
	background-image:url(/static/media/network.3cdf42d6.jpg);
	background-position:center center;
	background-size:cover;
	min-height: 200px;
	max-height: 500px;
	
}

.headerBanner  p{
		font-size: 4em;
	text-align: left;
	padding-left: 50px;
	 vertical-align: middle;
	 color: white;
}
 
.icon {
width: 100px;
}

.box1 {
	background-color: #a0c4ff;
	padding: 10px;
	height: 9em;
	margin: 10px;
	border-radius: 25px;
}

.center {
  justify-content: center;
}
.orderNoteItem {
border: 1px solid black;
text-align: justify;
margin: 10px;
}
.orderNoteText {

}
.orderNoteDate {
font-size: 0.7em;
}
.App {
  text-align: center;
 
    max-width: 980px;
    margin: 0 auto;
 
}

.App form {
	text-align: left;
	margin: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.photo {
	width: 100%;
}
/*
.App img {
	width: 50%;
}
*/

.intro {
	background-color: rgb(150,174,220);
	color: white;
	border-radius: 25px;
}

.subintro {
	margin: 10px;
}

.intro a:link, .intro a:hover, .intro a:visited, .intro a:active {
	text-decoration: none;
}
html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
    /* Set your content font stack here: */
    font-family: 'Inter', sans-serif;
}
